import React, { useState } from "react";
import WorkbookChartSection from "./WorkbookChartSection/WorkbookChart";
import WorkbookTabs from "./WorkbookTable/WorkbookTabs";
import "./workbook.scss";
import { Button } from "@carbon/react";
import { AddAlt } from "@carbon/react/icons";
import AddWorkbookFormModal from "./AddWorkbookFormModal";
import { useRuleWorkBook } from "hooks/ruleworkbook/useRuleWorkBook";
import Loading from "components/Loading";

const Workbook = () => {
  const { data: ruleWorkbookData, isLoading } = useRuleWorkBook();
  const [activeWorkbook, setActiveWorkbook] = useState();
  const [isAddWorkbookModalOpen, setIsAddWorkbookModalOpen] = useState(false);

  React.useEffect(() => {
    if (ruleWorkbookData?.length > 0) {
      setActiveWorkbook(ruleWorkbookData[0]);
    }
  }, [ruleWorkbookData]);

  if (isLoading) {
    return <Loading />;
  }

  const filteredWorkbook =
    activeWorkbook &&
    ruleWorkbookData?.find(
      (workbook) => workbook.dataPackageId === activeWorkbook.dataPackageId
    );

  return (
    <div className="workbook_main_container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Workbooks</h3>
        <Button
          kind="tertiary"
          renderIcon={AddAlt}
          onClick={() => setIsAddWorkbookModalOpen(true)}
        >
          Add New Workbook
        </Button>
      </div>
      <WorkbookChartSection
        dataPackages={ruleWorkbookData || []}
        setActiveWorkbook={setActiveWorkbook}
        filteredWorkbook={filteredWorkbook}
        activeWorkbook={activeWorkbook}
      />
      <WorkbookTabs runHistoryData={[]} errorsData={[]} />

      {isAddWorkbookModalOpen && (
        <AddWorkbookFormModal
          setIsAddWorkbookModalOpen={setIsAddWorkbookModalOpen}
          isAddWorkbookModalOpen={isAddWorkbookModalOpen}
        />
      )}
    </div>
  );
};

export default Workbook;
