import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";
async function stopJob({ jobId }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/job/${jobId}/stop`,
    body: {},
  });
}

export function useStopJobByJobId() {
  const queryClient = useQueryClient();

  return useMutation(({ jobId }) => stopJob({ jobId }), {
    onSuccess: () => {
      queryClient.invalidateQueries("Jobs");
    },
    onError: (error) => {
      return error;
    },
  });
}
