import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getRuntimeSizes() {
  return fetchWrapper({
    url: `${BASE_URL}/api/runtime/clusterSizes`,
    method: "GET",
  });
}

export function useRuntimeSizes() {
  return useQuery("RuntimeSizes", getRuntimeSizes, {
    onError: () => {
      console.error("Error getting Runtime sizes");
    },
  });
}
