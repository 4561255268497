import React from "react";
import TreeChart from "./TreeChart";

const height = 110;
const width = 160;
const typeArray = {
  Sandbox: "Sandbox",
  Silver: "Silver",
  Gold: "Gold",
  "": "",
};
function removeDuplicatesById(data) {
  const uniqueData = data.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = item;
    } else if (item.sibling) {
      acc[item.id] = item;
    }
    return acc;
  }, {});

  return Object.values(uniqueData);
}

const generateNodes = (nodeData, children = []) => {
  const sourceNodes = children
    ?.map((data) => {
      const sourceNode = nodeData.find((node) => node?.executionBand === data[0]?.source);
      if (!sourceNode) return null;

      const siblingArray = data.map((siblingData) => {
        const siblingNode = nodeData.find(
          (node) => node.executionBand === siblingData.target
        );
        return {
          title: siblingNode?.title || "- -",
          id: siblingNode?.id,
          description: siblingNode?.description || "",
          type: typeArray[siblingNode?.category],
        };
      });

      return {
        id: sourceNode.id,
        height: height,
        width: width,
        data: {
          title: sourceNode?.title || "- -",
          sibling: siblingArray?.length ? siblingArray : null,
          id: sourceNode.id,
          description: sourceNode?.description || "",
          type: typeArray[sourceNode?.category],
        },
      };
    })
    .filter(Boolean);

  const targetNodes = children
    ?.flatMap((data) => {
      return data.map((siblingData) => {
        const targetNode = nodeData.find(
          (node) => node.executionBand === siblingData.target
        );
        if (!targetNode) return null;

        return {
          id: targetNode.id,
          height: height,
          width: width,
          data: {
            title: targetNode?.title || "- -",
            id: targetNode?.id,
            description: targetNode?.description || "",
            type: typeArray[targetNode?.category],
          },
        };
      });
    })
    .filter(Boolean);

  const nodes = [...sourceNodes, ...targetNodes];
  return removeDuplicatesById(nodes);
};

const getChildren = (nodeData, edges = []) => {
  return nodeData?.flatMap((node) => {
    const childHavingSameTarget = edges?.filter(
      (edge) => edge?.target === (node?.executionBand + 1).toString()
    );

    const data = childHavingSameTarget?.map((value) => value);
    const groupedData = data.reduce((map, item) => {
      const target = item.target;

      if (map.has(target)) {
        map.get(target).push(item);
      } else {
        map.set(target, [item]);
      }

      return map;
    }, new Map());

    return Array.from(groupedData.values());
  });
};

const generatelink = (children) => {
  return children?.map((data) => ({
    id: `${data[0]?.id}`,
    from: `${data[0]?.source}`,
    to: `${data[0]?.target}`,
  }));
};

function WorkbookChart({
  dataPackages,
  nodeData = [],
  edgeData = [],
  activeWorkbook,
}) {
  const children = React.useMemo(
    () => getChildren(nodeData, edgeData),
    [nodeData, edgeData]
  );

  const nodesElement = React.useMemo(
    () => generateNodes(nodeData, children),
    [nodeData, children]
  );

  const linkElements = React.useMemo(
    () => generatelink(children),
    [children]
  );

  return (
    <TreeChart
      dataPackages={dataPackages}
      nodeCount={nodesElement.length}
      nodes={nodesElement}
      edges={linkElements}
      edgesCount={linkElements.length}
      activeWorkbook={activeWorkbook}
    />
  );
}

export default WorkbookChart;
