import React, { useEffect, useState } from "react";
import { AddAlt, Play } from "@carbon/react/icons";
import WorkbookChart from "../WorbookTreeChart";
import { Button } from "@carbon/react";

import WorkbookLeftColumn from "../WorkbookTable/WorkbookLeftColumn";
import AddNewNodeForm from "./AddNewNodeForm";
import { useRuleWorkBookDetail } from "hooks/ruleworkbook/useRuleWorkBookDetail"
import Loading from "components/Loading";

const WorkbookChartSection = ({
  dataPackages,
  setActiveWorkbook,
  filteredWorkbook,
  activeWorkbook,
}) => {
  const [isSideNavExpanded, setIsSideNavExpanded] = React.useState(false);
  const { data: activeWorkbookDetail, isLoadingDetail } = useRuleWorkBookDetail(activeWorkbook?.id);
  const [edgeNodeData, setEdgeNodeData] = useState([]);

  const onClickSideNavExpand = (e) => {
    setIsSideNavExpanded(!isSideNavExpanded);
    e.stopPropagation();
  };

  const mapToEdgeNodeData = (data) => {
    return data.map((item) => ({
      id: item.id,
      source: String(item.executionBand),
      target: String(item.executionBand + 1),
    }));
  };

  useEffect(() => {
    if (activeWorkbookDetail && Array.isArray(activeWorkbookDetail) && activeWorkbookDetail.length > 0) {
      const mappedData = mapToEdgeNodeData(activeWorkbookDetail);
      setEdgeNodeData(mappedData);
    }
  }, [activeWorkbookDetail]);

  if (isLoadingDetail) {
    return <Loading />;
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "2px",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <WorkbookLeftColumn
          columnData={dataPackages}
          setActiveWorkbook={setActiveWorkbook}
        />
      </div>
      <div className="workbook_chart_container">
        <div className="buttons_container">
          <div>
            <Button
              kind="tertiary"
              renderIcon={AddAlt}
              onClick={(event) => onClickSideNavExpand(event)}
            >
              Add New
            </Button>
          </div>
          <div>
            <Button renderIcon={Play}>Deliver</Button>
          </div>
        </div>

        {isSideNavExpanded && (
          <>
            <div className="modalBackdrop" onClick={onClickSideNavExpand} />

            <AddNewNodeForm onClickSideNavExpand={onClickSideNavExpand} />
          </>
        )}

        <div>
          <WorkbookChart
            dataPackages={dataPackages}
            nodeData={activeWorkbookDetail}
            edgeData={edgeNodeData}
            activeWorkbook={activeWorkbook}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkbookChartSection;
