import React from "react";
import {
  AFDropdown,
  AFFormModal,
  AFRadioButtonGroup,
  AFTextField,
} from "sharedComponents/Form";
import * as Yup from "yup";

const AddWorkbookFormModal = ({
  setIsAddWorkbookModalOpen,
  isAddWorkbookModalOpen,
  dataPackage = [],
}) => {
  const initialValues = {
    dataPackageID: "",
    name: "",
    description: "",
    isProduction: false,
  };

  const workbookValidationSchema = Yup.object().shape({
    dataPackageID: Yup.string().optional(),
    name: Yup.string().required(),
    description: Yup.string().required(),
    isProduction: Yup.boolean().required().default(false),
  });

  const dataPackageOptions = dataPackage?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const isProductionOptions = [
    { id: 1, value: "1", labelText: "Yes" },
    { id: 2, value: "0", labelText: "No" },
  ];

  const handleCreateWorkbook = (values) => {
    console.log("values", values);
  };
  return (
    <AFFormModal
      onClose={() => setIsAddWorkbookModalOpen(false)}
      onSubmit={handleCreateWorkbook}
      validationSchema={workbookValidationSchema}
      initialValues={initialValues}
      isOpen={isAddWorkbookModalOpen}
      title="New Workbook"
      primaryButtonText="Create"
    >
      <AFDropdown
        name="dataPackageID"
        label="Data Package ID"
        options={dataPackageOptions}
      />
      <AFTextField name="name" label="Name" />
      <AFTextField name="description" label="Description" />
      <AFRadioButtonGroup
        name="isProduction"
        label="Is Production"
        options={isProductionOptions}
        orientation={"horizontal"}
      />
    </AFFormModal>
  );
};

export default AddWorkbookFormModal;
